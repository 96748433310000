@import 'styles/_colors.scss';

.screen-deep-linking {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  > * {
    margin-bottom: 1.25rem;
  }

  &__label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 600;
    color: $black;
  }

  &__input {
    width: 100%;
    padding: 0.6rem 0.75rem;
    font-size: 1rem;
    border: 1px solid $grey;
    border-radius: 6px;
    transition: border-color 0.2s;

    &:focus {
      outline: none;
      border-color: $darkPink;
    }
  }

  &__hint {
    margin-top: -0.75rem;
    font-size: 0.85rem;
    color: $darkGrey;
    line-height: 1.4;
  }

  &__subtitle {
    margin: 0;
    font-size: 0.95rem;
    font-weight: 500;
    color: $black;
    margin-bottom: 0.5rem;
  }

  &__button {
    display: inline-block;
    background-color: $darkPink;
    color: $white;
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0.6rem 1.2rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }
}

.screen-route-btns-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.screen-route-copy-url {
  margin: 0px -16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.screen-route-save-button {
  margin: 0px -16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
